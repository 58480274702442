import React from 'react'
import Link from 'gatsby-link'
import { graphql } from "gatsby"
import * as utils from '../../utils'
import Layout from "../../components/layout"

export const query = graphql`
  query ActsQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(filter: {frontmatter: {type:{ eq:"artist"}}}) {
      edges {
        node {
          id
          fields {
            slug
            title
          }
          headings {
            value
            depth
          }
          frontmatter {
            nid
            vid
            type
            photo_filepath
            cover_filepath
            slug
            status
            timestamp
          }
          excerpt
        }
      }
    }
  }
`

const ActsPage = ({data}) => {
  var sortedartists = data.allMarkdownRemark.edges
    .filter(t => t.node && t.node.fields && t.node.fields.slug)
    .filter(t => t.node && t.node.frontmatter && t.node.frontmatter.status === 1)
    .sort((a, b) => {
      return a.node.fields.title.localeCompare(b.node.fields.title)
      // if (a.node.fields.title < b.node.fields.title) return -1
      // if (a.node.fields.title > b.node.fields.title) return 1
      // return 0
    });

  return <Layout>
    <div className="contentheader">
      <h1>OnArtist</h1>
      <p>Our crew of creative individuals</p>
    </div>
    <div className="covers">
      {
        sortedartists.map(t => {
          const thumbpath = t.node.frontmatter.cover_filepath || t.node.frontmatter.photo_filepath
          return <span key={t.node.id} className="cover">
            <Link to={'/' + t.node.fields.slug}>
              <img src={utils.getImageURL(thumbpath, 300)}
                alt={t.node.fields.title} title={t.node.fields.title} width="160" height="160" />
            </Link>
          </span>
        })
      }
    </div>
    {/* <h4>debug:</h4>
    <pre>{JSON.stringify(sortedartists, null, 2 )}</pre> */}
  </Layout>;
}

export default ActsPage
